import React from "react";
import ReactDOM from "react-dom/client";
import "./src/index.css";
import App from "./src/App";
import { Provider } from "react-redux";
import store from "./src/store";
const MainApp = () => {
  return (
    <>
      <Provider store={store}>
        <App />
      </Provider>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<MainApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
