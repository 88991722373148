import React from "react";
import UserRole from "./UserRoleDashboard";
import ManagementPanel from "./ManagementRole";

const AdminPanel = () => {
  return (
    <div>
      <UserRole />
    </div>
  );
};

export default AdminPanel;
