import React from "react";


const Error = () => {
  
  return (
    // <div>
    // <div className="w-full sticky top-0 z-20">
    // <Header />
    // </div>
    // <div className="flex bg-[#d9d9d9]">
    //   <div className="sticky top-32 h-screen">
    //     <SideBar />
    //   </div>
      <div className="text-2xl">
        <h1>Oops.. Page Not Found!!!</h1>
      </div>
    //    </div>
    // </div>
  );
};

export default Error;
