// import React from "react";
// import { Document, Page, Text, View, StyleSheet } from "react-pdf";

// // Create styles
// const styles = StyleSheet.create({
//   page: {
//     fontFamily: "Helvetica",
//     padding: 30,
//     fontSize: 12,
//   },
//   section: {
//     marginBottom: 10,
//   },
// });

// const InvoicePDF = () => {
//   return (
//     <div>
//       <Document>
//         <Page size="A4" style={styles.page}>
//           <View style={styles.section}>
//             <Text>Invoice</Text>
//           </View>
//           <View style={styles.section}>
//             <Text>Date: 25 April 2024</Text>
//           </View>
//           {/* Add the rest of your invoice content here */}
//         </Page>
//       </Document>
//     </div>
//   );
// };

// export default InvoicePDF;
